import React from "react";
import Link from "gatsby-link";
import "./404.scss";

const Page404 = () => {
	return (
		<section className="page404 full-width">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<div className="page404__big-title">404</div>
						<p>
							Запрашиваемая страница не найдена. <br />
							Вы можете вернуться к предыдущей <br />
							или на главную сайта.
						</p>
						<Link to="/">На главную ⟶</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Page404;
